<template>
<div>

  <div class="container-fluid wrapper-medium mt-3">
    <div class="row">
      <div class="col-12">
        <h1 class="anim-load1">
          Privacy Policy
        </h1>
      </div>
    </div>
  </div>

  <div class="container-fluid wrapper-medium mt-3 mb-5">
    <div class="row">
      <div class="col-12 col-md-10">
        <p>At Adoptionplus your privacy is a priority for us. Confidentiality and protection of your
information is a fundamental feature of our relationship with you. Adoptionplus Ltd is a
wholly owned subsidiary company of Barnardo’s. Adoptionplus address is; Moulsoe Business
Centre, Cranfield Road, Moulsoe, Newport Pagnell, MK16 0FJ.</p>
      <p>This policy sets out Adoptionplus’ approach to protect and respect your privacy in line with
UK General Data Protection Regulations, and it explains how, when and why we collect
personal information about people who contact us and use our website, and what we do
with that information.</p>
        <h3>Who are we?</h3>
        <p>Adoptionplus is a therapeutic Voluntary Adoption Agency. We provide a range of services to
support adoptive families, special guardians, foster carers, birth parents and professionals.
Adoptionplus have also created new products and services to benefit adoptive families,
foster families and special guardians; one of these services is TAYC-R.</p>
        <p>Adoptionplus collects personal data to enable us to provide services.</p>
        <h3>The type of personal information we collect regarding TAYC-R</h3>
        <strong>If you are an agency commissioning TAYC-R</strong>
        <p>The only pieces of personal information Adoptionplus holds about you are your first name,
surname, phone number, email address and the organisation for whom you work.</p>
<p>In order to protect anonymity, the TAYC-R tool will only ask you for your email address as
part of account creation. In order to ensure security of your data on the TAYC-R site, we
collect your mobile phone number as part of the two factor authentication process.</p>
        <strong>If you are an individual completing TAYC-R</strong>
        <p>If you are a parent or carer using TAYC-R the information will be fully anonymised and
Adoptionplus will hold none of your personal information. The Data Controller will be the
organisation who has asked you to complete the TAYC-R questionnaire. They will collect
and hold your personal data but this will not be shared with us.</p>
        <h3>Who has access to your personal information?</h3>
        <strong>Organisations or agencies commissioning TAYC-R</strong>
        <p>When any agency contacts Adoptionplus to commission TAYC-R we will collect your name,
contact details and the organisation for whom you work. We will not hold any other
personal information regarding you.</p>
        <p>Our third party processor Greenwood Campbell will have access to the anonymised results
of the TAYC-R Questionnaire. Greenwood Campbell have been rigorously assessed to
ensure that the service they provide to us meets the appropriate technical and
organisational level of security required for a tool of this nature.</p>
<p>We do not share or sell your personal data with anyone.</p>
        <h3>Your data protection rights</h3>
        <p>Under data protection law, you have rights, including:</p>
        <ul>
        <li><strong>Your right of access</strong> &ndash; Anyone completing the TAYC-R Questionnaire wishing to obtain their
data needs to contact their Agency Data Controller. You will need to contact the agency
who requested you complete the TAYC-R Questionnaire to obtain contact details of their
Data Protection Officer.</li>
        <li><strong>Your right to rectification</strong> - Anyone completing the TAYC-R Questionnaire wishing to change
their data needs to contact their Agency Data Controller. You will need to contact the
agency who requested you complete the TAYC-R Questionnaire to obtain contact details of
their Data Protection Officer.</li>
        <li>
            <strong>Your right to erasure</strong> - Anyone completing the TAYC-R Questionnaire wishing to erase their
            data needs to contact their Agency Data Controller. You will need to contact the agency
            who requested you complete the TAYC-R Questionnaire to obtain contact details of their
            Data Protection Officer.
        </li>
        <li><strong>Your right to restriction of processing</strong> - Anyone completing the TAYC-R Questionnaire
wishing to restrict their data needs to contact their Agency Data Controller. You will need to
contact the agency who requested you complete the TAYC-R Questionnaire to obtain
contact details of their Data Protection Officer.</li>
        <li><strong>Your right to object to processing</strong> - Anyone completing the TAYC-R Questionnaire wishing
to delete all their data needs to contact their Agency Data Controller. You will need to
contact the agency who requested you complete the TAYC-R Questionnaire to obtain
contact details of their Data Protection Officer.</li>
        </ul>
        <p>You are not required to pay any charge for exercising your rights. You can make a request by contacting us at: <br>
        <p><a class="link" href="mailto:DPO@barnardos.org.uk">DPO@barnardos.org.uk</a> or 07881 008967</p>
        <h3>How to complain</h3>
        <p>If you have any concerns about our use of your personal information, you can make a complaint to us at <a class="link" href="mailto:DPO@barnardos.org.uk">DPO@barnardos.org.uk</a>&nbsp;</p>
        <p>You can also complain to the ICO if you are unhappy with how we have used your data.</p>
        <h5>The ICO&rsquo;s address:</h5>
        <p>Information Commissioner&rsquo;s Office<br>
        Wycliffe House<br>
        Water Lane<br>
        Wilmslow<br>
        Cheshire<br>
        SK9 5AF</p>
        <p>Helpline number: 0303 123 1113</p>
        <p>ICO website: <a class="link" href="https://www.ico.org.uk">https://www.ico.org.uk</a></p>
        <h3>Review of this Policy</h3>
        <p>We will review this Policy from time to time and any changes will be available on our website.</p>
        <p>If you have any questions or complaints about our Privacy Policy or practices please contact:</p>
        <p>Joanne Alper, Director</p>
        <p>Email:&nbsp;&nbsp;<a class="link" href="mailto:joanne@adoptionplus.co.uk">joanne@adoptionplus.co.uk</a></p>
        <p>Telephone :&nbsp; 01908 218251</p>
        <p>&nbsp;</p>
        <h3>Data Protection Officer</h3>
        <p>Barnardo&rsquo;s Data Protection Officer, Martine King, can be contacted by email at: <a class="link" href="mailto:dpo@barnardos.org.uk">dpo@barnardos.org.uk</a>. You can also contact the ICO directly on 0303 123 1113 or contact us via live chat.</p>
      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>

export default {
  name: 'PrivacyPolicy',
  created: function () {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
  }
}
</script>
